<template>
  <v-container>
    <v-card class="elevation-2  mx-auto mt-2" :loading="loading" max-width="800" v-for="(row_data, index) in template_list" :key="index">
      <v-card-title>
        <v-row dense>
          <v-col cols="3">
            {{row_data.title}}
          </v-col>
          <v-col cols="9" class="text-right">
            {{row_data.short_code}}
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="row_data.subject" label="Subject" :hide-details="!row_data.form_error.subject" :error-messages="row_data.form_error.subject" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Text</label>
              <vue-editor
                v-model="row_data.text"
                :editor-toolbar="customToolbar"
              />
              <div class="error--text">
                {{row_data.form_error.text}}
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn class="mr-4" color="secondary" @click="updateTemplate(row_data, index)">Update</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapMutations } from "vuex";
export default {
  name: "Setting",
  data() {
    return {
      template_list: [],
      loading: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
    };
  },
  components: {
    VueEditor
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateTemplate(row_data, index) {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`admin/email_template/edit`, row_data)
        .then((response) => {
          _self.template_list[index].form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.template_list[index].form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getTemplateList() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.get(`admin/email_template/list`)
        .then((response) => {
          console.log(response)
          _self.template_list = response.data.data
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
  mounted: function () {
    this.getTemplateList();
  },
};
</script>